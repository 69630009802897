import React, { useState, useCallback } from 'react';
import { useTranslation } from 'next-i18next';

import { useRouter } from 'next/router';
import useCountDown from '@/utils/hooks/useCountDown';
import Button, { TypeButton } from '@/components/NewButton';
import Live from '../Live';
import { ClassTypeEnum, LeaveTypeEnum, SubjectTypeEnum } from '../interface';

import StatusComponent from '../../Curriculum/Status';
import LeaveComponent from '../../Curriculum/Leave';

import 'video.js/dist/video-js.css';
import { generateNamePrefix } from '@/utils/helpers';
import { log } from '@/api/log';
import PATHS from '@/utils/paths';
import { getTalkCloudLink } from '@/api/home';
import CourseInfo from '@/pageComponents/Common/CourseInfoNew';
import { StatusEnum } from '@/api/types/curriculum';
import { checkLeave } from '@/api/curriculum';
import { Toast } from '@/components';
import { forbiddenBrowser } from '@/utils/utils';
import styles from './style.module.scss';
import useModuleName from '@/store/useModule';
import { StudentInfo } from '@/api/types/home';
import { User } from '@/api/types/login';
// import ClassPreparation from '@/pageComponents/Autonomy/ClassPreparation';
import ParentalVerification from '@/pageComponents/Autonomy/ClassPreparation/ParentalVerification';
import useUser from '@/store/useUser';
import { traceEvent } from '@wk/wk-gatherer';
import useTraceEventWithAccount from '@/utils/hooks/useTraceEventWithAccount';

interface LectureProps {
  canAdjustTime: boolean;
  canMonitor: boolean;
  timeOffset: number;
  platform?: string;
  subject: SubjectTypeEnum;
  unitTitle: string;
  title: string;
  classType: ClassTypeEnum;
  classTypeName: string;
  duration: string;
  teacher: {
    name: string;
    avatar: string;
  };
  hlsUrl: string;
  className?: string;
  onEnterClass?: () => void;
  onEnterData?: () => void;
  hasTeachingMaterials?: boolean;
  courseType?: 'TRIAL' | string;
  courseScheduleId: string;
  startDateTime: string;
  endDateTime: string;
  qrUrl?: string;
  uuid: string;
  status: StatusEnum;
  showLeaveButton: boolean;
  onRefresh?: () => void;
  isLast?: boolean;
  onBeforeClick: () => boolean;
  curStudent: StudentInfo | null;
  account: User | null;
  trialStudent: boolean;
  isFirst?: boolean;
  isAttendance?: boolean; // 判断是否所有学员未出席过课程
}

const prefixName = generateNamePrefix('home.');

const DEFAULT_ENTER_CLASS_SECOND = 11 * 60;

const DEFAULT_ENTER_TALK_CLOUD_CLASS_SECOND = 20 * 60;

const Lecture = (props: LectureProps) => {
  const {
    account,
    curStudent,
    timeOffset,
    subject,
    unitTitle,
    classType,
    classTypeName,
    title,
    platform,
    teacher,
    onEnterClass,
    onEnterData,
    hlsUrl,
    hasTeachingMaterials,
    startDateTime,
    endDateTime,
    courseScheduleId,
    canAdjustTime,
    canMonitor,
    qrUrl,
    // uuid,
    status,
    showLeaveButton,
    isLast,
    onRefresh = () => {},
    onBeforeClick,
    trialStudent,
    isFirst,
    isAttendance,
  } = props;
  const { t: curriculumTranslation, i18n } = useTranslation('curriculum');
  const { t } = useTranslation('home');
  const [liveVisible, setLiveVisible] = useState(false);
  const [leaveModalVis, setLeaveModalVis] = useState(false);
  const [LeaveModalType, setLeaveModalType] = useState<LeaveTypeEnum>(LeaveTypeEnum.PRIMARY_LEAVE_APPLY);
  const router = useRouter();
  const { isCompletedParentalVerification } = useUser();
  const { updateModuleName } = useModuleName();
  const [countTime] = useCountDown(timeOffset);
  const [showVerificationModal, setShowVerificationModal] = useState<boolean>(false);
  const { traceEventWithAccount } = useTraceEventWithAccount();

  const enterClassTime = platform === 'TALK_CLOUD' ? DEFAULT_ENTER_TALK_CLOUD_CLASS_SECOND : DEFAULT_ENTER_CLASS_SECOND;
  const canEnter = countTime === 0 || countTime < enterClassTime;

  const handleChangeDate = () => {
    if (!onBeforeClick()) {
      updateModuleName('最近课程');
      return;
    }

    router.push(
      `${
        PATHS.trainAdjust
      }?type=${subject.toLocaleLowerCase()}&title=${unitTitle}&timeOffset=${timeOffset}&startDateTime=${startDateTime}&courseScheduleId=${courseScheduleId}&endDateTime=${endDateTime}&qr=${qrUrl}`,
    );
  };

  const handleLeave = useCallback(async () => {
    traceEventWithAccount({
      _event: 'U_LatestCourse_DayOffClick',
      CourseSchedule_id: courseScheduleId,
      subject: subject,
    });
    if (!onBeforeClick()) {
      updateModuleName('最近课程');
      return;
    }
    const { message, success, urgent } = await checkLeave({ courseScheduleId });
    // 如果是私教课分2种case(正常请假 or 紧急请假)
    if (classType === ClassTypeEnum.PRIVATE) {
      if (success) {
        setLeaveModalType(LeaveTypeEnum.PRIMARY_LEAVE_APPLY);
        setLeaveModalVis(true);
      } else if (urgent) {
        setLeaveModalType(LeaveTypeEnum.URGENT_LEAVE_APPLY);
        setLeaveModalVis(true);
      } else {
        Toast.warning(message);
      }
    } else if (classType === ClassTypeEnum.GROUP) {
      if (success) {
        setLeaveModalType(LeaveTypeEnum.ADJUST_LESSON_APPLY);
        setLeaveModalVis(true);
      } else {
        Toast.warning(t('已提交调课申请'));
      }
    }
  }, [courseScheduleId, classType]);

  const handleCloseModal = useCallback(() => {
    setLeaveModalVis(false);
    onRefresh();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    // classType === ClassTypeEnum.PRIVATE && onRefreshData();
  }, []);

  const isProcesssingClass = status === StatusEnum.NORMAL && timeOffset === 0;

  return (
    <section className={styles.container}>
      <LeaveComponent
        classType={classTypeName}
        gtmPrefix={prefixName}
        lessonTitle={title}
        lessonUnitTitle={unitTitle}
        lessonTeacherName={teacher.name}
        lessonStartDateTime={startDateTime}
        lessonEndDateTime={endDateTime}
        visible={leaveModalVis}
        type={LeaveModalType}
        courseScheduleId={courseScheduleId}
        onClose={handleCloseModal}
      />
      <Live visible={liveVisible} onClose={() => setLiveVisible(false)} src={hlsUrl} />
      <CourseInfo
        showHeader={false}
        locale={i18n.language as 'zh' | 'en'}
        startDateTime={startDateTime}
        endDateTime={endDateTime}
        subTitle={unitTitle}
        classType={classTypeName}
        fullName={teacher.name}
        teacherHeadImg={teacher.avatar}
        courseTitle={title}
        enablePoint={false}
        enableDivider={false}
        isProcesssingClass={isProcesssingClass}
        enableBorder={false}
        isLast={isLast}
        operateRender={() => {
          return (
            <>
              {canEnter && hasTeachingMaterials && (
                <Button
                  type={!canEnter ? 'primary' : 'default'}
                  text={curriculumTranslation(t('本课重点'))}
                  onClick={() => {
                    traceEventWithAccount({
                      _event: 'U_LatestCourse_PreviewClick',
                      CourseSchedule_id: courseScheduleId,
                      subject: subject,
                    });
                    traceEvent({
                      data: {
                        _event: `U_CourseCard_PreviewClick`,
                        account_id: account?.uuid,
                        CourseSchedule_id: courseScheduleId,
                      },
                    });
                    onEnterData?.();
                  }}
                />
              )}
              {canAdjustTime && !canEnter && (
                <Button text={curriculumTranslation('课程调整')} onClick={handleChangeDate} />
              )}
              {showLeaveButton && countTime > 0 && (
                <StatusComponent
                  classType={classType}
                  showLeaveButton={showLeaveButton}
                  onEnd={() => {}}
                  onLeave={handleLeave}
                  subject={subject}
                  status={status}
                  timeOffset={timeOffset}
                  platform={platform}
                />
              )}
              {canMonitor && (
                <Button
                  text={curriculumTranslation(t('直播'))}
                  // shieldIcon={!canEnter}
                  onClick={async () => {
                    if (isCompletedParentalVerification) {
                      setShowVerificationModal(true);
                      return;
                    }
                    if (!onBeforeClick()) {
                      updateModuleName('最近课程');
                      return;
                    }
                    if (platform === 'TALK_CLOUD') {
                      if (forbiddenBrowser()) {
                        Toast.warning(t('课中直播不支持使用火狐、IE或者Opera浏览器，建议使用Chrome浏览器去上课。'));
                        return;
                      }
                      if (account) {
                        traceEventWithAccount({
                          _event: 'U_LatestCourse_SuperviseClassClick',
                          CourseSchedule_id: courseScheduleId,
                          subject: subject,
                          enterType: 'AUDITOR',
                        });
                        const link = await getTalkCloudLink({
                          courseScheduleId,
                          clientType: 'WEB',
                          userType: 'AUDITOR',
                          uuid: account.uuid,
                          name: account?.fullName?.trim() || 'Student',
                          language: i18n.language === 'en' ? 'EN_US' : 'ZH_CN',
                          trialStudent: subject === 'MATH' && trialStudent,
                          norecord: curStudent && !curStudent.setting.playbackPrivacyStatus,
                        });
                        if (link) {
                          window.open(link);
                        }
                      }
                    } else {
                      setLiveVisible(true);
                    }
                    log(prefixName`forget_pwd`);
                  }}
                />
              )}
              <Button
                type={TypeButton.RED}
                processing={canEnter}
                text={curriculumTranslation('进教室')}
                className={canEnter ? '' : styles.unactive}
                onClick={() => {
                  traceEventWithAccount({
                    _event: 'U_LatestCourse_EnterClassroomClick',
                    CourseSchedule_id: courseScheduleId,
                    subject: subject,
                    enterType: 'STUDENT',
                  });
                  if (isAttendance && isFirst) {
                    traceEvent({
                      data: {
                        _event: `U_PreparationTop_EnterClassroomClick`,
                        account_id: account?.uuid,
                        CourseSchedule_id: courseScheduleId,
                      },
                    });
                  }
                  if (isCompletedParentalVerification) {
                    setShowVerificationModal(true);
                    return;
                  }
                  if (!canEnter) {
                    return Toast.warning(t('自主化-课前20分钟可进入教室', { time: t('自主化-课前时间') }));
                  }
                  onEnterClass?.();
                }}
              />
              {!canEnter && hasTeachingMaterials && (
                <Button
                  type={!canEnter ? 'primary' : 'default'}
                  text={curriculumTranslation(t('本课重点'))}
                  onClick={() => {
                    traceEventWithAccount({
                      _event: 'U_LatestCourse_PreviewClick',
                      CourseSchedule_id: courseScheduleId,
                      subject: subject,
                    });
                    if (isAttendance && isFirst) {
                      traceEvent({
                        data: {
                          _event: `U_PreparationTop_PreviewClick`,
                          account_id: account?.uuid,
                          CourseSchedule_id: courseScheduleId,
                        },
                      });
                    } else {
                      traceEvent({
                        data: {
                          _event: `U_CourseCard_PreviewClick`,
                          account_id: account?.uuid,
                          CourseSchedule_id: courseScheduleId,
                        },
                      });
                    }

                    onEnterData?.();
                  }}
                />
              )}
            </>
          );
        }}
      />
      {/* {isAttendance && isFirst && (
        <div className={styles.classPreparationWrap}>
          <ClassPreparation
            wrapClassName={styles.customWrapClass}
            courseScheduleId={courseScheduleId}
            enterClassRoomBtn={
              <Button
                type={TypeButton.RED}
                processing={canEnter}
                text={curriculumTranslation('进教室')}
                className={canEnter ? '' : styles.unactive}
                onClick={() => {
                  traceEvent({
                    data: {
                      _event: `U_Preparation_EnterClassroomClick`,
                      account_id: account?.uuid,
                      CourseSchedule_id: courseScheduleId,
                    },
                  });
                  if (isCompletedParentalVerification) {
                    setShowVerificationModal(true);
                    return;
                  }
                  if (!canEnter) {
                    return Toast.warning(t('自主化-课前20分钟可进入教室', { time: t('自主化-课前时间') }));
                  }
                  onEnterClass?.();
                }}
              />
            }
          />
        </div>
      )} */}
      <ParentalVerification
        open={showVerificationModal}
        onClose={() => {
          setShowVerificationModal(false);
        }}
      />
    </section>
  );
};

export default Lecture;
