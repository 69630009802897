import Image from 'next/image';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import styles from './style.module.scss';
import { ICourseInfo } from '@/pageComponents/CancelCourse/interface';
import useDeviceInfo from '@/utils/hooks/useDeviceInfo';
import {
  CLASS_REPORT,
  HOMEWORK,
  PREPARE,
  applaudIcon,
  TRIAL_CARD_EXPOSE,
  DEFAULT_ENTER_TALK_CLOUD_CLASS_SECOND,
  DEFAULT_ENTER_CLASS_SECOND,
} from '@/pageComponents/Home/TrialCourse/constants';
import TeacherInfo from '../TeacherInfo';
import CourseBaseInfo from '../CourseBaseInfo';
import Prepare, { PRE_CLASS_PREVIEW } from '../Prepare';
import Homework from '../Homework';
import ClassReport from '../ClassReport';
import TrialTraceDataContext from '../TrialTraceDataContext';
import _ from 'lodash';
import useCountDown from '@/utils/hooks/useCountDown';
import JoinClass from './components/JoinClass';

interface IProps {
  course: Partial<ICourseInfo>;
}
const TrialCourseSteps = ({ course }: IProps) => {
  const { i18n } = useTranslation('trailCourse');
  const {
    courseScheduleId,
    courseSubject,
    courseType,
    sectionNameDisplay,
    sectionNameDisplayEn,
    crmReport,
    homeworkCompleted,
    timeOffset = 0,
    platform,
    status,
  } = course || {};
  const { studentResult: deviceTested, reportList, memorizedSetReport } = useDeviceInfo();
  const [applaudPicShow, setApplaudPicShow] = useState<boolean>(true);
  // 是否点过预习按钮
  const isPreviewed = !!reportList?.[PRE_CLASS_PREVIEW]?.includes(courseScheduleId as string);
  // 是否有学习报告
  const hasReport = !!crmReport?.trialReportUrl;
  const [countTime] = useCountDown(timeOffset);
  const enterClassTime = platform === 'TALK_CLOUD' ? DEFAULT_ENTER_TALK_CLOUD_CLASS_SECOND : DEFAULT_ENTER_CLASS_SECOND;
  const canEnter = countTime === 0 || countTime < enterClassTime;

  const baseTraceData = useMemo(() => {
    return {
      courseType,
      courseScheduleId,
      subject: courseSubject,
      preparationStatus: !!deviceTested,
      previewStatus: isPreviewed,
      courseStatus: status,
      homeworkStatus: homeworkCompleted,
      reportStatus: hasReport,
      scheduleName: i18n.language === 'zh' ? sectionNameDisplay : sectionNameDisplayEn,
    };
  }, [
    courseScheduleId,
    courseSubject,
    courseType,
    deviceTested,
    hasReport,
    homeworkCompleted,
    i18n.language,
    isPreviewed,
    sectionNameDisplay,
    sectionNameDisplayEn,
    status,
  ]);

  const CollapseConfig = [
    {
      content: (course: Partial<ICourseInfo>, index: number, availableListLen: number) => {
        return (
          <Prepare
            course={course}
            step={index}
            deviceTested={deviceTested}
            isPreviewed={isPreviewed}
            availableListLen={availableListLen}
            handleTaskCollapse={handleTaskCollapse}
            memorizedSetReport={memorizedSetReport}
            canEnter={canEnter}
          />
        );
      },
      key: PREPARE,
    },
    {
      content: (course: Partial<ICourseInfo>, index: number) => {
        return <Homework course={course} step={index} />;
      },
      key: HOMEWORK,
    },
    {
      content: (course: Partial<ICourseInfo>, index: number) => {
        return (
          <ClassReport course={course} step={index} reportList={reportList} memorizedSetReport={memorizedSetReport} />
        );
      },
      key: CLASS_REPORT,
    },
  ];

  const getAvailableConfigList = (course: Partial<ICourseInfo>) => {
    const _CollapseConfig = CollapseConfig?.filter((item) => {
      const { key } = item || {};
      const { homeworkPublish, courseSubject } = course || {};
      if (key === HOMEWORK) {
        return homeworkPublish ? { ...item } : null;
      }
      if (key === CLASS_REPORT) {
        return courseSubject === 'CHINESE' ? { ...item } : null;
      }
      return { ...item };
    });
    return _CollapseConfig?.filter(Boolean);
  };

  useEffect(() => {
    const len = getAvailableConfigList(course)?.length;
    setApplaudPicShow(len > 1);
  }, []);
  const handleTaskCollapse = (flag: boolean) => {
    const len = getAvailableConfigList(course)?.length;
    if (len > 1) {
      setApplaudPicShow(true);
    } else {
      setApplaudPicShow(flag);
    }
  };

  return (
    <TrialTraceDataContext.Provider value={baseTraceData}>
      <div
        className={styles.trialStepsMainContent}
        key={course.courseScheduleId}
        data-expose-event={TRIAL_CARD_EXPOSE}
        data-expose-data={JSON.stringify({
          ..._.pick(baseTraceData, [
            'courseScheduleId',
            'scheduleName',
            'subject',
            'courseType',
            'preparationStatus',
            'previewStatus',
            'homeworkStatus',
            'reportStatus',
            'courseStatus',
          ]),
        })}
      >
        <TeacherInfo {...course} key={course.courseScheduleId} />
        <div className={styles.courseInfo}>
          <CourseBaseInfo course={course} />
          <JoinClass canEnter={canEnter} {...course} />
          <div className={styles.stepContent}>
            <div className={styles.stpes}>
              {getAvailableConfigList(course)?.map((config, index) => {
                return (
                  <div className={styles.stepItem} key={config.key}>
                    <div className={styles.stepItemContent}>
                      {config.content(course, index, getAvailableConfigList(course)?.length)}
                    </div>
                  </div>
                );
              })}
            </div>
            {/* 任务只有一个并且这一个任务收起来了, 就不展示图片 */}
            {applaudPicShow && (
              <div className={styles.applaudPic}>
                <Image src={applaudIcon} width={140} height={96} alt="" />
              </div>
            )}
          </div>
        </div>
      </div>
    </TrialTraceDataContext.Provider>
  );
};
export default TrialCourseSteps;
