import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import { Dropdown, Toast } from '@/components';
import qs from 'query-string';
import { ICourseInfo } from '@/pageComponents/CancelCourse/interface';
import { forbiddenBrowser } from '@/utils/utils';
import { getTalkCloudLink } from '@/api/home';
import useUser from '@/store/useUser';
import {
  TRIAL_CARD_MORE_CANCEL_CLASS_CLICK,
  TRIAL_CARD_MORE_SUPERVISE_CLICK,
  TRIAL_CARD_TO_MORE_CLICK,
  moreIcon,
} from '../constants';
import styles from './style.module.scss';
import router from 'next/router';
import { traceEvent } from '@wk/wk-gatherer';
import { useContext } from 'react';
import TrialTraceDataContext from '../TrialTraceDataContext';

const TeacherInfo = ({
  teacherName,
  showMonitorButton,
  cancelButton,
  teacherAvator,
  courseScheduleId = '',
  courseSubject,
  platform,
}: Partial<ICourseInfo>) => {
  const { curStudent, account } = useUser();
  const { t: curriculumT } = useTranslation('curriculum');
  const { t, i18n } = useTranslation('trailCourse');
  const traceData = useContext(TrialTraceDataContext);

  const handleLiveClass = async () => {
    traceEvent({
      data: {
        _event: TRIAL_CARD_MORE_SUPERVISE_CLICK,
        ..._.pick(traceData, ['courseScheduleId', 'scheduleName', 'subject']),
        joinType: '家长',
      },
    });
    // TODO: 目前只判断了TALK_CLOUD
    if (platform === 'TALK_CLOUD') {
      if (forbiddenBrowser()) {
        Toast.warning(t('课中直播不支持使用火狐、IE或者Opera浏览器，建议使用Chrome浏览器去上课。'));
        return;
      }
      if (account) {
        const link = await getTalkCloudLink({
          courseScheduleId,
          clientType: 'WEB',
          userType: 'AUDITOR',
          uuid: account.uuid,
          name: account?.fullName?.trim() || 'Student',
          language: i18n.language === 'en' ? 'EN_US' : 'ZH_CN',
          trialStudent: true,
          norecord: curStudent && !curStudent.setting.playbackPrivacyStatus,
        });
        if (link) {
          window.open(link);
        }
      } else {
        Toast.error('Missing curStudent');
      }
    }
  };
  const routeToCancelCourse = () => {
    traceEvent({
      data: {
        _event: TRIAL_CARD_MORE_CANCEL_CLASS_CLICK,
        ..._.pick(traceData, ['courseScheduleId', 'scheduleName', 'subject']),
      },
    });
    const url = qs.stringifyUrl({
      url: i18n.language === 'zh' ? '/cancel-course' : '/en/cancel-course',
      query: {
        courseScheduleId,
        courseSubject,
      },
    });
    router.push(url);
  };

  return (
    <div className={styles.teacherHeader}>
      <div className={styles.baseInfo}>
        <div className={styles.avatorBox}>
          <img className={styles.avator} src={teacherAvator} alt="avator" />
        </div>
        <div className={styles.name}>{teacherName}</div>
      </div>
      {(showMonitorButton || cancelButton) && (
        <Dropdown
          mouseEnterTraceClick={() => {
            traceEvent({
              data: {
                _event: TRIAL_CARD_TO_MORE_CLICK,
                ..._.pick(traceData, ['courseScheduleId', 'scheduleName', 'subject']),
              },
            });
          }}
          optionClassName={styles.dropdownContainer}
          optionChildren={
            <div className={styles.funcbox}>
              {showMonitorButton && (
                <span className={styles.funcItem} onClick={handleLiveClass}>
                  {curriculumT('直播')}
                </span>
              )}
              {cancelButton && (
                <span className={styles.funcItem} onClick={routeToCancelCourse}>
                  {t('取消约课-按钮')}
                </span>
              )}
            </div>
          }
        >
          <div className={styles.moreAction}>
            <img className={styles.moreIcon} src={moreIcon.src} alt="more" />
          </div>
        </Dropdown>
      )}
    </div>
  );
};
export default TeacherInfo;
